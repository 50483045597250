import { render, staticRenderFns } from "./main-menu.vue?vue&type=template&id=2da531ec&scoped=true&"
import script from "./main-menu.vue?vue&type=script&lang=js&"
export * from "./main-menu.vue?vue&type=script&lang=js&"
import style0 from "./main-menu.vue?vue&type=style&index=0&id=2da531ec&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2da531ec",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox, CGrid: require('@chakra-ui/vue').CGrid, CFlex: require('@chakra-ui/vue').CFlex})
