var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative",
      "z-index": "3",
      "margin-block": "12px",
      "border-radius": ['12px', '0px'],
      "background-color": "#FFF",
      "box-shadow": ['4px 4px 10px rgba(0, 0, 0, 0.15)', 'none']
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "max-width": "1100px",
      "justify-content": "center",
      "align-items": "center",
      "mx": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "justify-content": "center",
      "align-content": "center",
      "px": ['16px', '24px'],
      "py": "12px",
      "pos": "relative"
    }
  }, [_vm.listMenu.length > 0 ? _c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": ['repeat(12, 1fr)', "repeat(".concat(_vm.listMenu.length > 7 ? 7 : _vm.listMenu.length, ", 1fr)")],
      "mx": "auto",
      "padding-inline": "8px",
      "gap": ['12px', '12px']
    }
  }, _vm._l(_vm.listMenu, function (menu, index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "position": "relative",
        "grid-column": [index < 3 ? 'span 4 / span 4' : 'span 3 / span 3', 'span 1 / span 1']
      }
    }, [menu !== null && menu !== void 0 && menu.route ? _c('c-button', {
      attrs: {
        "as": "router-link",
        "to": menu === null || menu === void 0 ? void 0 : menu.route,
        "target": menu !== null && menu !== void 0 && menu.isNewTab ? '_blank' : '',
        "href": menu === null || menu === void 0 ? void 0 : menu.href,
        "display": "flex",
        "width": "100%",
        "height": ['92px', '108px'],
        "min-height": ['unset', '108px'],
        "max-width": ['100%', '200px'],
        "mx": "auto",
        "flex-direction": "column",
        "jusity-content": "center",
        "align-items": "center",
        "border": "none",
        "border-radius": ['12px', '12px'],
        "padding": ['8px', '8px'],
        "cursor": "pointer",
        "color": "#111111",
        "background-color": "#FFF",
        "white-space": "pre-warp",
        "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)']
      }
    }, [_c('c-image', {
      attrs: {
        "src": menu.icon,
        "height": ['35px', '60px'],
        "width": "auto"
      }
    }), _c('c-text', {
      attrs: {
        "mt": ['8px', '8px'],
        "font-size": ['12px', '18px'],
        "text-align": "center",
        "font-weight": ['400', '500'],
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(menu.textMenu) + " ")])], 1) : _c('c-button', {
      attrs: {
        "as": "a",
        "target": menu !== null && menu !== void 0 && menu.isNewTab ? '_blank' : '',
        "href": menu === null || menu === void 0 ? void 0 : menu.href,
        "display": "flex",
        "width": "100%",
        "height": ['92px', '108px'],
        "min-height": ['unset', '108px'],
        "max-width": ['100%', '200px'],
        "mx": "auto",
        "flex-direction": "column",
        "jusity-content": "center",
        "align-items": "center",
        "border": "none",
        "border-radius": ['12px', '12px'],
        "padding": ['8px', '8px'],
        "cursor": "pointer",
        "color": "#111111",
        "background-color": "#FFF",
        "white-space": "pre-warp",
        "box-shadow": ['2px 2px 10px rgba(0, 0, 0, 0.15)']
      }
    }, [_c('c-image', {
      attrs: {
        "src": menu.icon,
        "height": ['35px', '60px'],
        "width": "auto"
      }
    }), _c('c-text', {
      attrs: {
        "mt": ['8px', '8px'],
        "font-size": ['12px', '18px'],
        "text-align": "center",
        "font-weight": ['400', '500'],
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(menu.textMenu) + " ")])], 1), menu !== null && menu !== void 0 && menu.withRibbonNew ? _c('c-box', {
      staticClass: "ribbon"
    }, [_vm._v(" NEW ! ")]) : _vm._e()], 1);
  }), 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }