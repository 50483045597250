<template>
  <c-box
    position="relative"
    z-index="3"
    margin-block="12px"
    :border-radius="['12px', '0px']"
    background-color="#FFF"
    :box-shadow="['4px 4px 10px rgba(0, 0, 0, 0.15)', 'none']"
  >
    <!-- Program dan Layanan Dietela -->
    <c-flex
      width="100%"
      max-width="1100px"
      justify-content="center"
      align-items="center"
      mx="auto"
    >
      <c-flex
        flex-direction="column"
        width="100%"
        justify-content="center"
        align-content="center"
        :px="['16px', '24px']"
        py="12px"
        pos="relative"
      >
        <c-grid
          v-if="listMenu.length > 0"
          width="100%"
          :template-columns="['repeat(12, 1fr)' ,`repeat(${listMenu.length > 7 ? 7 : listMenu.length}, 1fr)`]"
          mx="auto"
          padding-inline="8px"
          :gap="['12px', '12px']"
        >
          <c-box
            v-for="(menu, index) in listMenu"
            :key="index"
            position="relative"
            :grid-column="[index < 3 ? 'span 4 / span 4' : 'span 3 / span 3', 'span 1 / span 1']"
          >
            <c-button
              v-if="menu?.route"
              as="router-link"
              :to="menu?.route"
              :target="menu?.isNewTab ? '_blank' : ''"
              :href="menu?.href"
              display="flex"
              width="100%"
              :height="['92px', '108px']"
              :min-height="['unset', '108px']"
              :max-width="['100%', '200px']"
              mx="auto"
              flex-direction="column"
              jusity-content="center"
              align-items="center"
              border="none"
              :border-radius="['12px', '12px']"
              :padding="['8px', '8px']"
              cursor="pointer"
              color="#111111"
              background-color="#FFF"
              white-space="pre-warp"
              :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
            >
              <c-image
                :src="menu.icon"
                :height="['35px', '60px']"
                width="auto"
              />
              <c-text
                :mt="['8px', '8px']"
                :font-size="['12px', '18px']"
                text-align="center"
                :font-weight="['400', '500']"
                font-family="Roboto"
              >
                {{ menu.textMenu }}
              </c-text>
            </c-button>
            <c-button
              v-else
              as="a"
              :target="menu?.isNewTab ? '_blank' : ''"
              :href="menu?.href"
              display="flex"
              width="100%"
              :height="['92px', '108px']"
              :min-height="['unset', '108px']"
              :max-width="['100%', '200px']"
              mx="auto"
              flex-direction="column"
              jusity-content="center"
              align-items="center"
              border="none"
              :border-radius="['12px', '12px']"
              :padding="['8px', '8px']"
              cursor="pointer"
              color="#111111"
              background-color="#FFF"
              white-space="pre-warp"
              :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)']"
            >
              <c-image
                :src="menu.icon"
                :height="['35px', '60px']"
                width="auto"
              />
              <c-text
                :mt="['8px', '8px']"
                :font-size="['12px', '18px']"
                text-align="center"
                :font-weight="['400', '500']"
                font-family="Roboto"
              >
                {{ menu.textMenu }}
              </c-text>
            </c-button>
            <c-box
              v-if="menu?.withRibbonNew"
              class="ribbon"
            >
              NEW !
            </c-box>
          </c-box>
        </c-grid>
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script>
import { LINK_DIETELA_SHOP, LINK_IF_PROGRAM_AFTER_LOGIN } from '@/constants/generals'
import { flags } from '@/utils/flags'
import { mapGetters } from 'vuex'

export default {
  name: 'MainMenu',
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
    listMenu() {
      return [
        {
          id: 1,
          textMenu: 'Program',
          ...flags.redirectLinkMainMenu(this.isAuthenticated),
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/program_menu.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676450338677',
          isNewTab: false,
        },
        {
          id: 2,
          textMenu: 'Panduan Diet',
          route: { name: 'client.content-challenge' },
          icon: require('@/assets/icons/icon-content-challenge.svg'),
          isNewTab: false,
        },
        {
          id: 3,
          textMenu: 'Nilai Gizi',
          route: { name: 'client.glossaries', query: { posthog: 'click menu nilai gizi' } },
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/glossary_menu.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676450338672',
          isNewTab: false,
        },
        {
          id: 4,
          textMenu: 'Pilih Ahli Gizi',
          route: { name: 'client.nutritionists' },
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/nutritionist_menu_2_.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1676453456144',
          isNewTab: false,
        },
        {
          id: 5,
          textMenu: 'Toko',
          href: this.LINK_DIETELA_SHOP,
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/refactor_landing_page/shop_10374480%201.webp?updatedAt=1714983825217',
          isNewTab: this.$isMobile() ? false : true,
        },
        {
          id: 6,
          textMenu: 'AjakSehat',
          route: { name: 'client.profile.referral' },
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/refactor_landing_page/teamwork_13450349%201.webp?updatedAt=1714983825293',
          isNewTab: false,
        },
        {
          id: 7,
          textMenu: 'IF Program',
          href: this.LINK_IF_PROGRAM,
          icon: 'https://ik.imagekit.io/dietela/pwa_webp/user_dashboard/if-program.webp',
          isNewTab: false,
          withRibbonNew: true,
        },
      ]
    },
    LINK_DIETELA_SHOP() {
      return LINK_DIETELA_SHOP
    },
    LINK_IF_PROGRAM() {
      return LINK_IF_PROGRAM_AFTER_LOGIN
    },
  },
}
</script>

<style scoped>
/* SOURCE REF: https://css-generators.com/ribbon-shapes/ */
.ribbon {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}
.ribbon {
  --f: .5em; /* control the folded part */
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1.75;
  padding-inline: 1lh;
  padding-bottom: var(--f);
  border-image: conic-gradient(#0008 0 0) 51%/var(--f);
  clip-path: polygon(
    100% calc(100% - var(--f)),100% 100%,calc(100% - var(--f)) calc(100% - var(--f)),var(--f) calc(100% - var(--f)), 0 100%,0 calc(100% - var(--f)),999px calc(100% - var(--f) - 999px),calc(100% - 999px) calc(100% - var(--f) - 999px));
  transform: translate(calc((cos(45deg) - 1)*100%), -100%) rotate(-45deg);
  transform-origin: 100% 100%;
  background-color: #D32737; /* the main color  */
}

@media (min-width: 768px) {
  .ribbon {
    font-size: 14px;
  }
}
</style>